/* Login button style */

.login-modal-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.input-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-modal-style-input {
    height: 45px;
    width: 80%;
    margin: 7px;
    padding: 10px 37px;
    border-radius: 20px;
    border: 1px solid silver;
    box-sizing: border-box;
    font-size: medium;
}


.login-modal-icon {
    position: absolute;
    top: 50%;
    left: 11%;
    transform: translateY(-50%);
}

.login-modal-style-btn {
    width: 70%;
    font-weight: 600;
    font-size: 15px;
    border-radius: 8px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 44px;
    position: relative;
    cursor: pointer;
    word-break: keep-all;
    white-space: nowrap;
    background: rgb(255, 255, 255);
    animation: auto ease 0s 1 normal none running none;
    border-width: 1px;
    border-style: solid;
    color: rgb(22, 24, 35);
    border-color: rgba(22, 24, 35, 0.12);
}

/* Login button style */

/* SignUp page style */
.signUp-modal-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.signUp-modal-terms {
    position: absolute;
    bottom: 80px;
    font-size: 12px;
    text-align: center;
    padding: 16px 30px;
}

/* SignUp page style */