/* layout css */
.profile-layout-bg-container {
    display: flex;
    flex-direction: column;
    align-items: flex-startclear;
    width: 100%;
    background-color: #fff;
}

.profile-layout-sidebar {
    width: 300px;
    border-right: 1px solid silver;
}

.profile-layout-middle-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* layout css */

.profile-home-bg-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
}

.profile-home-bg-main-container::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}

/* Optional: Add this for older versions of Internet Explorer */
.profile-home-bg-main-container {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}



.active-btn {
    border-bottom: 3px solid rgb(47, 123, 209);
}


.video-short {
    width: 100%;
    height: 100%;
    border: none;
}

/* ####################### Profile-Page-Edit-Content ############################*/
.profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
}

.profile-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: Arial, sans-serif;
    font-size: larger;
    width: 100%;
    margin-left: 5px;
}

.avatar-initial-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.avatar-initial-details:nth-child(2) {
    font-weight: 400;
    font-size: 18px;
}

.avatar-initial-username {
    font-size: 32px;
    font-weight: bold;
    margin-right: 10px;
    font-weight: 700;
}

.edit-profile-btn {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: rgb(22, 24, 35);
    border-color: rgba(22, 24, 35, 0.12);
    background-color: rgb(255, 255, 255);
    min-height: 36px;
    font-weight: 600;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    height: 36px;
    font-size: 16px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 16px;
    min-width: 136px;
    line-height: 24px;
    margin-top: 8px;
    margin-right: 8px;
}

.profile-stats {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.stat {
    flex: 1;
    text-align: center;
    font-weight: 700;
    flex-direction: column;
    font-size: large;
}

.stat :nth-child(2) {
    font-weight: 400;
    font-size: 16px;
}

.stat :nth-child(2):hover {
    text-decoration: underline;
    cursor: pointer;
}

/* ####################### Profile-Page-Edit-Content ############################*/


/* Video Section */

.layout-bg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-layout-main-container {
    position: relative;
    display: flex;
    width: 100%;
}

.layout-middle-container {
    width: 60%;
}

/* .home-bg-main-container {
    padding: 20px;
} */

.profile-video-main-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
}

.profile-header {
    display: flex;
    align-items: center;
    width: 100%;
}

.profile-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-initial-details {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.avatar-initial-username {
    font-size: 24px;
    font-weight: bold;
}

.avatar-initial-username-id {
    font-size: 16px;
    color: gray;
}

.edit-profile-btn {
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
}

.edit-profile-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.portfolio-details .portfolio-item {
    height: 50px;
    width: 98%;
    font-weight: 500;
    box-sizing: border-box;
    /* Ensures padding and border are included in the element's total width and height */
    display: flex;
    /* Optional: if you want to align the span elements horizontally */
    align-items: center;
    /* Optional: vertically center the content */
    margin-bottom: 5px;
    /* Optional: space between items */
    justify-content: space-between;
}


.bio {
    font-size: medium;
}


/* ####################### Video-Category-Buttons ############################*/
.profile-tabs {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px 40px 0px 20px;
    border-bottom: 1px solid silver;
    border-radius: 5%;
}

.content-category-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px 40px 0px 20px;
    border-bottom: 1px solid silver;
    border-radius: 5%;
}

.tab {
    flex: 1 1;
    padding: 10px;
    text-align: center;
    border: none;
    background-color: white;
    margin: 2px;
    border-radius: 3%;
    cursor: pointer;
    font-size: 18px;
    justify-content: center;
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 500;
}

.tab:hover {
    text-decoration: underline;
}

.tab.active {
    background-color: #ddd;
    /* Keep the hover effect color for the active tab */
}

/* ####################### Video-Category-Buttons ############################*/

/* ####################### Save Video-Category ############################*/
.profile-liked-btn-section {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-around;
}

.profile-liked-btn-style {
    background-color: "lightgrey";
    width: fit-content;
    padding: 0 9% 0 9%;
    margin: 5px 3px 0 0;
    height: 30px;
    border-radius: 5px;
    background-color: #ddd;
    border: none;
    cursor: pointer;
    font-size: 15px;
}

.profile-liked-btn-style.active {
    background-color: rgb(204, 200, 200);
}

.profile-liked-btn-style:hover {
    background-color: rgb(204, 200, 200);
}

/* ####################### Save Video-Category ############################*/

/* ####################### Video-Thumbnail-Card ############################*/
.video-sections {
    width: 100%;
}

.video-thumbnail {
    width: 100%;
    height: 300px;
    border-radius: 5px;
}

.video-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 10px 20px 0px 20px;
}

.video-card {
    background: #f4f4f4;
    padding: 5px 5px 3px 5px;
    border-radius: 5px;
    text-align: center;
}

.video-title {
    margin-top: 10px;
    font-weight: bold;
}

/* ####################### Modal-Non-Scrollable-Part ############################*/
.profile-fixed-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    padding: 10px;
    /* border-bottom: 1px solid #ccc; */
}

.profile-scrollable-content {
    max-height: 67vh;
    /* Adjust the height as needed */
    overflow-y: auto;
    padding: 10px;
}

/* ####################### Modal-Non-Scrollable-Part End ############################*/


/* ####################### Video-Thumbnail-Card ############################*/

@media screen and (max-width:1100px) {
    .profile-layout-sidebar {
        width: 70px;
    }
}