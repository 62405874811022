/* index.css */

/* Main container */
.searchComponent-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.login-modal-icon {
    position: absolute;
    top: 50%;
    left: 11%;
    transform: translateY(-50%);
}

.search-liked-btn-style {
    width: fit-content;
    padding: 0 5% 0 5%;
    margin: 5px 3px 0 0;
    height: 30px;
    border: none;
    cursor: pointer;
    font-size: 15px;
    background-color: white;
}

.search-liked-btn-style.active {
    border-bottom: 2px solid black;
    display: inline-block;
}

.search-liked-btn-style:hover {
    border-bottom: 2px solid black;
    display: inline-block;
}