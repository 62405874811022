.chat-page {
    display: flex;
    font-family: 'Arial', sans-serif;
    height: 100vh;
    background-color: rgb(237 237 237 / 62%);
}

.message-list {
    width: 30%;
    border-right: 1px solid #ccc;
    padding: 10px;
    height: 98vh;
}

.message-list-container {
    margin: 7px;
    padding: 5px;
    /* border: 1px solid silver; */
    height: 90%;
    border-radius: 20px;
    width: 94%;
    overflow-y: auto;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px;

}

.message-list-container::-webkit-scrollbar {
    display: none;
}

.message-home-return {
    font-size: 20px;
    font-weight: bold;
    margin-right: 50%;
    margin-left: 2%;
    padding: 7px 7px 7px 10px;
    border-radius: 2%;
    display: flex;
    align-items: center;
}

.conversation-snippet {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.conversation-snippet span {
    color: #333;
    margin-right: 5px;
}

.conversation-snippet span:last-child {
    color: #888;
}

.messages-profile-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 5px;
}

.unread-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    margin-left: 5px;
}



.chat-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.chat-header img {
    width: 50px;
    margin-right: 10px;
}

.warning-icon {
    color: red;
    margin-left: auto;
}

.chat-bubble {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #ddd;
    border-radius: 20px;
    width: fit-content;
    border: 1px solid #ccc;
}

.restricted .error-icon {
    display: inline-block;
    color: red;
    margin-left: 5px;
}

::placeholder {
    color: #aaa;
}

/* Changes Chat Panel Right*/
.chat-panel {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* margin-top: auto; */
    position: relative;
    /* Ensure the chat panel takes the full height of the viewport */
}

.chat-user-section {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    font-size: large;
    font-weight: 600;
}

.chat-messages-section {
    margin: 7px;
    padding: 5px;
    /* border: 1px solid silver; */
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px;
    height: 90%;
    margin-top: 50px;
    border-radius: 2%;
    width: 94%;
    overflow-y: auto;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column-reverse;
}

.chat-messages-section::-webkit-scrollbar {
    display: none;
}

.message-input {
    /* margin-top: auto; */
    /* Pushes the message input to the bottom */
    padding: 10px 0;
}

.message-input input {
    width: calc(96% - 20px);
    margin: 7px;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid silver;
}

/* Additional styles based on image description */
.no-content {
    text-align: center;
    /* Centers the 'No conversations available' text */
    color: #888;
    /* Grey text color for 'No conversations available' */
}