/* .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-left: 1px solid silver;
} */
.rightSidebar {
    /* position: fixed;*/
    width: 100%;
    border-left: 1px solid silver;
    height: 100vh;
    padding: 10px 0px 0px 0px;
}

.profile img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.profile button {
    background: none;
    border: none;
    font-size: 24px;
}

.interaction_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin: 10px;
    position: absolute;
    bottom: 25%;
    font-weight: 500;
    width: 19%;
}

.interaction-main-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.interactions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;

}

/* comments-container  */

.comments-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 83%;
    margin-left: 20px;
    margin-top: 20px !important;
    padding-right: 10px;
    margin-top: 0px;
    border: 1px solid #000;
    padding: 0px 10px 10px 10px;

}

.comments-heading {
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.hr-line {
    border: .5px solid #000;
    width: 100%;
    margin-top: 0px;
    padding-top: 0px;
}

.user-img {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    border: .5px solid #000;
}

.user-comments {
    font-size: 12px;
    font-weight: 700;
    color: #000;
    line-height: 1rem;
    margin-top: 2px;
}

.user-comments-container {
    height: 85vh;
    width: 96%;
    overflow-x: scroll;
    border: .5px solid #000;
    padding: 5px;
    background-color: aliceblue;
}

.user-img-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.user-name {
    font-size: 15px;
    font-weight: 600;
    color: #1e201d;
    margin-left: 4px;
}

.interactions button {
    display: flex;
    align-items: center;
    /* gap: 10px; */
    background: none;
    border: none;
    font-size: 24px;
    width: 50px;
    height: 50px;
    background-color: aliceblue;
    border-radius: 50%;
    justify-content: center;
}

.interactions span {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    width: 50px;
}



.header_button {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    align-items: flex-start;
    width: 95%;
    gap: 10px;
    margin-right: 3%;
    padding-left: 5px;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .header_button {
        flex-direction: column;
    }
}

.uploadButton,
.loginButton {
    min-width: 80px;
    height: 30px;
    /* padding: 10px; */
    border-radius: 5px;
    background-color: #ddd;
    border: none;
    cursor: pointer;
    font-size: 15px;
}

@media screen and (max-width: 768px) {

    .uploadButton,
    .loginButton {
        margin-top: 20px;
    }
}

.uploadButton {
    background-color: rgb(254, 44, 85);
    color: white;
}

/* .menuIcon {
    font-size: 24px;
    cursor: pointer;
    margin-right: 5%;
} */

@media screen and (max-width: 600px) {
    .searchBar {
        display: none;
    }
}

/* App.css */

.comment-section {
    overflow-x: scroll;
    height: 92vh;
    width: 100%;
    /* max-width: 300px; */
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    /* padding-left: 0px !important; */
    font-family: Arial, sans-serif;
}

.comment {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.comment-avatar,
.reply-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.comment-content {
    flex: 1;
}

.comment-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.comment-username {
    font-weight: bold;
}

.comment-time {
    font-size: 0.9em;
    color: #888;
}

.comment-text {
    margin-bottom: 5px;
}

.comment-footer {
    display: flex;
    align-items: center;
    font-size: 0.85em;
    color: #888;
}

.comment-reply,
.view-replies {
    cursor: pointer;
    margin-right: 10px;
}

.comment-like {
    cursor: pointer;
    color: red;
    margin-right: 5px;
}

.comment-likes {
    margin-right: 10px;
}

.replies {
    margin-left: 0px;
    margin-top: 10px;
}

.reply {
    display: flex;
    align-items: flex-start;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}

.comments-reply-bottom {
    display: flex;
    flex-direction: column;
}

.reply-forms {
    display: flex;
    flex-direction: column;
}

.reply-form {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.replies-like {
    font-size: 0.85em;
    color: #888;
    display: flex;
    align-items: center;
}


.reply-input {
    flex: 1;
    padding: 5px;
    /* margin-right: 10px; */
    border: 1px solid #ddd;
    border-radius: 4px;
}

.reply-button {
    align-self: self-end;
    width: 90px;
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    /* margin-right: 10px; */
    margin-top: 5px;
}

.reply-next {
    font-size: 10px;
    width: 30px;
    padding: 3px 3px;
    border: none;
    background-color: #dbe0e7;
    color: rgb(24, 21, 21);
    /* border-radius: 4px; */
    cursor: pointer;
    margin-top: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reply-button:hover {
    background-color: #0056b3;
}

.back-to-top {
    text-align: right;
    margin-top: 10px;
    padding-right: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #007bff;
    /* align-self: self-end; */
}

.back-to-top-down {
    position: fixed;
    top: 95vh;
    right: 1%;
    cursor: pointer;
    font-weight: bold;
    color: #007bff;
}


/* popup */

.manage-comment-popup {
    position: fixed;
    right: 1.5%;
    top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: linear-gradient(135deg, #ff7e5f, #feb47b); */
    background-color: #978686;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    width: 170px;
    color: white;
    /* z-index: 1000; */
    font-family: 'Helvetica Neue', sans-serif;
    opacity: 0;
    transform: translateY(-50px);
    animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.manage-comment-popup:hover {
    transform: translateY(-5px);
}

.manage-comment-popup .close-icon {
    align-self: flex-end;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 5px;
    transition: transform 0.5s ease-in-out;
}

.manage-comment-popup .close-icon:hover {
    transform: rotate(180deg);
}

.manage-comment-popup button {
    background: #56ccf2;
    color: white;
    border: none;
    /* padding: 12px 20px; */
    margin: 8px 0;
    border-radius: 5px;
    cursor: pointer;
    width: 70%;
    height: 30px;
    font-size: 14px;
    font-weight: bold;
    transition: background 0.3s, transform 0.3s;
}

.manage-comment-popup button:hover {
    background: #2f80ed;
    transform: scale(1.05);
}

.manage-comment-popup .edit-button {
    background: silver;
}

.manage-comment-popup .edit-button:hover {
    background: rgb(169, 152, 152);
}

.manage-comment-popup .delete-button {
    background: #eb5757;
}

.manage-comment-popup .delete-button:hover {
    background: #c0392b;
}

/* Additional Animation for Buttons */
@keyframes buttonBounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.manage-comment-popup button:active {
    animation: buttonBounce 0.5s;
}

/* popup */

.comments-length {
    font-size: 15px;
    color: #000;
    font-weight: 600;
}

/* share button */
.share-button-container {
    position: fixed;
    /* top: 80%; */
    bottom: 2%;
    width: auto;
    height: auto;
    /* right: 4%; */
    display: flex;
    flex-direction: column;
}

.share-button {
    width: 230px;
    height: 130px;
    flex-wrap: wrap;
    display: flex;
    background-color: #978686;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    color: white;
    font-family: 'Helvetica Neue', sans-serif;
    opacity: 0;
    transform: translateY(-50px);
    animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.share-button:hover {
    transform: translateY(-5px);
}

.share-button .close-icon {
    align-self: flex-end;
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 5px;
    transition: transform 0.5s ease-in-out;
}

.share-button .close-icon:hover {
    transform: rotate(180deg);
}

.share-button button {
    background: #56ccf2;
    color: white;
    border: none;
    /* padding: 12px 20px; */
    margin: 8px 0;
    border-radius: 5px;
    cursor: pointer;
    width: 30%;
    height: 30px;
    font-size: 14px;
    font-weight: bold;
    transition: background 0.3s, transform 0.3s;
}

.share-option {
    height: 30px;
    margin: 8px 15px;
}

.share-button button:hover {
    background: #2f80ed;
    transform: scale(1.05);
}

/* Additional Animation for Buttons */
@keyframes buttonBounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.share-button button:active {
    animation: buttonBounce 0.5s;
}

/* popup */

.share-container {
    position: fixed;
    top: 15%;
    right: 28%;
    width: 600px;
    background-color: #1c1c1e;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
    margin-top: 50px;
}

.share-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.search-input {
    width: 80%;
    padding: 5px;
    border: none;
    border-radius: 5px;
}

.close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
}

.suggested-list {
    max-height: 400px;
    overflow-y: auto;
}

.suggested-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #333;
}

.profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-info {
    flex-grow: 1;
}

.username {
    display: block;
    color: #fff;
    font-weight: bold;
}

.handle {
    color: #aaa;
}

.select-user {
    margin-left: 10px;
}

.send-button {
    width: 100%;
    padding: 10px;
    background-color: #007aff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}
