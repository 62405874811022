/* ExploreStyle.css */
.container {
    /* background-color: black; */
    color: black;
    display: flex;
    align-items: center;
    padding: 7px 10px 7px 10px;
    margin-bottom: 10px;
}

.container:hover {
    background-color: #D3D3D3;
    border-radius: 15px;
    width: fit-content;
}

.icon {
    margin-right: 10px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
    /* Adjust spacing as needed */
}



.text {
    font-size: 23px;
    font-weight: 1000;
    color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1000px) {
    .text {
        display: none;
    }

}