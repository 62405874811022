.sidebar_container {
    padding: 0px 0px 10px 0px;
    width: 300px;
    height: 100vh;
    border-right: 2px solid silver;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: fixed; */
    background-color: "#f4f5f7";
}

.side_logo {
    width: 120px;
    height: 70px;
}

.sidebar_elements {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* margin-top: 20px; */
}

@media screen and (max-width: 1000px) {
    .sidebar_container {
        width: 70px;
    }

    .side_logo {
        width: 70px;
        height: 50px;
    }
}