/* layout css */
.layout-bg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
}

.layout-main-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.layout-sidebar {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* width: 300px; */
    /* min-height: 100%; */
    /* padding: 30px; */
    background-color: "#f4f5f7";
    width: 300px;
}

.layout-middle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    background-color: #36454F;
}

.Right-sidebar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
}

/* layout css */

.home-bg-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    height: 101vh;
    overflow-y: scroll;
}


.active-btn {
    border-bottom: 3px solid rgb(47, 123, 209);
}

.video-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.video-container {
    width: 100%;
    height: 98vh;
    /* background-color: #36454F; */
}

.video-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    gap: 20px;
}

.video-short {
    width: 100%;
    height: 100%;
    border: none;
}

/* Notification element style */

.notification-list-container {
    padding: 5px;
    height: 90%;
    border-radius: 20px;
    width: 94%;
    overflow-y: auto;
    background-color: rgb(255, 255, 255);
}

.notification-list-container::-webkit-scrollbar {
    display: none;
}


.notification-list-container::-webkit-scrollbar {
    display: none;
}

.notification-snippet {
    padding: 10px;
    margin-bottom: 4px;
    cursor: pointer;
    display: flex;
    border-radius: 17px;
    align-items: center;
    background-color: #dddddd5e;
}

.notification-snippet span {
    color: #333;
    margin-right: 5px;
}

.notification-snippet span:last-child {
    color: #888;
}

/* Notification element style */
.video-wrapper {
    position: relative;
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
}

.video-wrapper video {
    width: 100%;
    height: 100vh;
    object-fit: contain;
}

.video-button-container {
    position: absolute;
    top: 50%;
    right: 2%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translateY(-50%);
}

.video-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
}

.video-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.video-short {
    width: 100%;
    height: 100%;
    border: none;
}

.loading-indicator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    color: red;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1001;
}

.video-title {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    bottom: 40px;
    left: 23%;
    width: 52.3%;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
}

.video-text {
    line-height: 1.5rem;
    font-size: 15px;
    font-weight: 400;
    font-style: oblique;
}
