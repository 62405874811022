.postButton {
    background-color: rgb(254, 44, 85);
    color: #FFFFFF;
    padding: 20px 20px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.postButton:hover {
    background-color: #FF7A33;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
}

.modal-content h2 {
    margin-top: 0;
}

.error-message {
    color: red;
    margin-top: 10px;
}

/* buttonElm.css */

@media screen and (max-width: 1000px) {
    .upload-text-style {
        display: none;
    }
}