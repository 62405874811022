.profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
}

.edit-profile-btn {
    background-color: #3897f0;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.upload-button {
    background-color: #3897f0;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.portfolio-details .portfolio-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.portfolio-details .portfolio-item span {
    font-size: 16px;
}

.scrollable-content {
    overflow-y: auto;
    max-height: 300px;
}

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 1000;
    padding: 10px;
}

.profile-video-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.profile-stats {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

.stat {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profile-stats-box {
    margin-top: 20px;
}

.profile-stats-box-inner {
    display: flex;
    justify-content: space-around;
}

.stats {
    display: flex;
}

.profile-posts-container {
    margin-top: 20px;
    width: 100%;
}

.profile-videos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.video-item {
    position: relative;
    width: 200px;
    height: 200px;
}

.video-item video {
    width: 100%;
    height: 100%;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    opacity: 0;
    transition: opacity 0.3s;
}

.video-item:hover .video-overlay {
    opacity: 1;
}

.diamond-icon {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.diamond-icon img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.hamburger-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.unlock-profile-container {
    margin-top: 20px;
}

.unlock-profile-button {
    background-color: #3897f0;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.avatar-initial-username,
.avatar-initial-username-id {
    margin-top: 10px;
    font-size: 18px;
}