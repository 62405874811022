/* UserProfile.css */
.userProfile {
    display: flex;
    align-items: center;
    /* background-color: black; */
    color: white;
    width: 250px;
    padding: 20px 20px;
    /* position: fixed; */
    bottom: 0;
    border-radius: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.userProfile:hover {
    background-color: #D3D3D3;
}

.userProfile-avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.userProfile-username {
    color: grey;
    margin: 15px;
    color: black;
}

.options {
    margin-left: auto;
    color: black;
    font-size: 20px;
}

@media screen and (max-width: 1000px) {
    .sidebar_container {
        width: 70px;
    }

    .userProfile {
        display: flex;
        align-items: center;
        /* background-color: black; */
        color: white;
        width: 50px;
        padding: 20px 20px;
        bottom: 0;
        border-radius: 30px;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .username {
        display: none;
    }

    .options {
        display: none;
    }
}