/* VideoThumbnail.css */

.song-layout-video-thumbnail {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 4px 14px 4px 10px;
    background-color: rgba(239, 237, 237, 1);
    cursor: pointer;
}

.song-layout-video-thumbnail:hover {
    background-color: rgba(191, 191, 191, 0.792);
}

.song-layout-index-number {
    font-size: 16px;
    margin-right: 10px;
}

.song-layout-thumbnail-image {
    width: 80px;
    height: 60px;
    object-fit: cover;
    /* margin-right: 10px; */
}

.song-layout-video-title {
    font-size: 14px;
    font-weight: bold;
}

.song-layout-channel-info {
    font-size: 12px;
    color: #777;
}

.song-layout-options-menu {
    font-size: 18px;
    margin-left: auto;
}